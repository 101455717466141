<template>
  <div class="ly_news_cb_main">
      <div class="ly_news_cb_title">菌种鉴定</div>
      <div class="ly_news_cb_hot_so">1、简介</div>
      <p>（1）传统的生化方法对微生物进行正确的分类存在较大的困难。而随着分子生物学技术的发展，核酸序列分析已被广泛的应用于微生物鉴定中，通过基因序列分析，可以快速准确地对微生物的种属进行鉴定。目前、原核生物的种属鉴定主要采用16S rDNA测序技术，真核生物主要采用18S rDNA或ITS测序。 </p>
      <p>（2）16S rDNA是编码原核生物核糖体小亚基Rrna(16S rRNA)的DNA序列，存在于所有细菌染色体基因组中。16S rDNA分子大小适中，突变率小，是细菌系 统分类学研究中最常用，最有用的“分子钟”。其序列包含9个可变区（variable region）和10个恒定区（constant region）。保守序列区域反映了生物物种 间的亲缘关系，而高变序列区域则能体现物种间的差异。16S rDNA的序列特征为不同分类级别的近缘种系统分类奠定了分子生物学基础。</p>

      <p>（3）与细菌多样性分析类似，在真核微生物中也有三类核糖体RNA（rRNA），包括5.8S rRNA、18S rRNA和28S rRNA。18S rRNA基因是编码真核生物核糖体小亚基的DNA序列，其中既有保守区，也有可变区（V1-V9，没有V6区）。保守区域反映了生物物种间的亲缘关系，而可变区则能体现物种间的差异，适用于作种级及以上的分类标准。其中，V4区是文献中常用的检测片段。</p>

      <p>（4）ITS序列是内源转录间隔区（Internally Transcribed Spacer），位于真菌18S、5.8S和28S rRNA基因之间，分别为ITS 1和ITS 2。 在真菌中，5.8S、18S和28S rRNA基因具有较高的保守性，而ITS由于承受较小的自然选择压力，在进化过程中能够容忍更多的变异，在绝大多数真核生物中表现出极为广泛的序列多态性。同时，ITS的保守型表现为种内相对一致，种间差异较明显，能够反映出种属间，甚至菌株间的差异。并且ITS序列片段较小（ITS 1和ITS 2长度分别为350 bp和400 bp），易于分析，目前已被广泛用于真菌不同种属的系统发育分析。 </p>

      <p>（5）使用PCR技术，对16S rDNA,18S rDNA或ITS序列进行扩增，然后PCR产物进行测序，即可获得相应的碱基序列。然后将序列在NCBI上进行Blast比对，即可获知与该序列同源性较高的已知序列，为确定菌种的分类学地位提供依据。</p>

      <div class="ly_news_cb_hot_so">2、实验流程</div>
      <p><img src="https://beinabncc.oss-cn-beijing.aliyuncs.com/News/b95d60652046df1a272d97e839ebf39e.png" alt="实验流程"></p>
      <div class="ly_news_cb_hot_so">3、送样要求</div>
      <p>（1）请您提供给我们由至少分离三次以上的单菌落培养的平板或斜面，如果因实验样品不纯造成的测序套峰，本公司将收取全额实验费用；</p>
      <p>（2）如果您的菌体带有致病性，请您提供给我们菌体的基因组DNA，并将其进行加热处理以杀死致病菌。</p>
      <div class="ly_news_cb_hot_so">4、送样流程</div>
      <p>（1）下载附件<a href="${bncc_detection_download_2!'https://beinabncc.oss-cn-beijing.aliyuncs.com/News/45d64ddc22b58103c9af7d8595799300.xls'}"  rel="nofollow">《BNCC菌种鉴定服务订单》</a>，并详细填写其中的内容</p>
      <p>（2）寄送样本到本公司</p>
      <div class="ly_news_cb_hot_so">5、提供结果</div>
      <p>（1）测序峰图文件及序列文件</p>
      <p>（2）鉴定报告一份</p>
      <div class="ly_news_cb_hot_so">6、其它</div>
      <p>菌种鉴定需7至10个工作日。</p>
  </div>
  <footer>
    <ul class="footer-nav">
      <li class="footer-li">
        <a
          href="https://tb.53kf.com/code/client/099be4cda651b04a92a215ad1e6edd8d8/1"
        >
          <!-- <img src="https://www.bzwz.com/static/m/images/icon52.png" alt="" /> -->
          <img src="@/assets/img/common/icon52.png" alt="" />
          客服
        </a>
      </li>

      <li class="footer-li button download" @click="onBack()" style="background-color: #df0024">
        <a href="javascript:;"> 返回 </a>
      </li>
    </ul>
  </footer>
</template>

<script lang="ts">
import {
  defineComponent
} from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "identiFication",
  setup() {
    const router = useRouter();
    function onBack() {
      router.back()
    }
    return {
      onBack
    };
  },
});
</script>


<style lang="scss" scoped>
.ly_news_cb_main {
  padding: 15px;
  font-size: 0.42667rem;
  line-height: 0.66667rem;
  color: #666;
  padding-bottom: 1.33333rem;
}
img{
    width:100%;
}
a{
    color:#05b3d2;
}
p {
  margin: 1em 0;
  word-break: break-word;
  text-indent: 20px;
}

.ly_news_cb_title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #444;
}

.ly_news_cb_hot_so {
  font-weight: 600;
  color: #444;
  font-size: 18px;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  border-top: 1px solid #f4f4f4;
  font-size: 14px;
  color: #666;
  background-color: #fff;
  z-index: 100;
}
footer a {
  font-size: 14px;
  color: #666;
  display: block;
}

.footer-li {
  float: left;
  width: 17%;
  text-align: center;
  position: relative;
}
.footer-li img {
  display: block;
  width: 19.5px;
  height: 19.5px;
  margin: 5px auto;
}

.footer-li .active-status {
  position: absolute;
  display: none;
  left: 50%;
  margin-left: -9.75px;
  top: 0;
  z-index: 10;
  background-color: #fff;
}

.footer-nav .active a {
  color: #df0024;
}

.footer-nav .active .active-status {
  display: block;
}

footer .button {
  width: 110px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background-color: #e7c527;
  color: #fff;
  border-radius: 36px;
  margin-top: 8px;
  margin-right: 10px;
}
footer .button a {
  color: #fff;
}
.download {
  float: right;
}
</style>